.webcam-page-wrapper{
    min-height: 100vh;
    padding: 120px 0 20px 0;
    display: flex;
    flex-direction: column;
}

.webcam-page-commands{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0px 50px;
    align-items: center;
}

.webcam-container{
    display: flex;
    flex-direction: row;
    margin: 20px 20px;
}

.webcam-container img{
    max-width: 50%;
}

.webcam-description{
    padding: 10px 20px;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
}

.webcam-description p{
    text-align: left;
}

.webcam-description a{
    color: var(--primary);
}

.webcam-banner-container{
    margin: 0 50px;
}

.webcam-banner-container h2{
    padding: 20px 0px;
    margin-left: 20px;
}

.webcam-banner-row{
    display: flex;
    flex-direction: row;
}

.webcam-banner-row a{
    margin-right: 20px;
}

.webcam-slider-container h2{
    padding: 20px 0;
}

.separator{
    align-self: center;
}

@media screen and (max-width: 960px){
    .webcam-page-commands{
        flex-direction: column;
    }

    .webcam-container{
        flex-direction: column;
    }

    .webcam-container img{
        max-width: 100%;
    }

    .webcam-banner-row{
        flex-direction: column;
        align-items: center;
    }

    .webcam-banner-container {
        margin: 0 20px;
    }

    .webcam-banner-container h2{
        text-align: center;
        margin: 0;
    }

    .webcam-banner-row > a > img{
        width: 100%;
    }

    .webcam-banner-row > a{
        margin-right: 0;
        margin-bottom: 20px;
    }

    .webcam-description div{
        padding: 10px 0;
    }

    .webcam-description div p{
        text-align: justify;
    }

    .webcam-description p{
        padding: 20px 0;
    }
}