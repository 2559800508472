.webcam-wrapper{
    min-height: 100vh;
    justify-content: flex-start;
    display: flex;
    flex-direction: column;
    padding-top: 120px;
}

.province-list-filters{
    padding: 0 20%;
}
.province-list-filters ul{
    list-style: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.webcam-wrapper h1{
    text-align: center;
    padding: 20px 0;
}

.webcam{
    display: block;
    margin: 10px 20px;
}

.webcam-list{
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.webcam-list-item{
    text-decoration: none;
}

.webcam-list-item li{
    list-style: none;
    padding: 20px 40px;
    background-color: #eee;
    color: black;
    border-top: 1px solid black;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.webcam-list-item li:hover{
    background-color: #ddd;
}

.webcam-list-item-info{
    display: flex;
    flex-direction: row;
}

.webcam-list-item-info i{
    padding: 0 24px 0 0;
}

.webcam-search-title{
    text-align: center;
    padding: 10px;
}

@media screen and (max-width: 960px){
    .webcam{
        margin: 0;
    }

    .province-list-filters{
        padding: 0;
    }
    
    .province-list-filters ul{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .province-list-filters ul li{
        width: 100%;
    }
}