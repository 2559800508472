
.navbar{
    width: 100%;
    position: fixed;
    z-index: 2;
}
.navbar-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: gray;
    padding: 0 50px;
    max-height: 120px;
    position: relative;
    z-index: 2;

    -webkit-transition: all 0.2s ease-in;
    -moz-transition: all 0.2s ease-in;
    -o-transition: all 0.2s ease-in;
    transition: all 0.2s ease-in;
}

#scroll-loading{
    background-color: var(--primary);
    height: 5px;
    width: 0;
}

.sticky-navbar{
    max-height: 60px;
}

.navbar-logo{
    width: 256px;
    -webkit-transition: all 0.2s ease-in;
    -moz-transition: all 0.2s ease-in;
    -o-transition: all 0.2s ease-in;
    transition: all 0.2s ease-in;
}

.navbar-logo img{
    max-width: 100%;
    max-height: 100%;
}

.report-link{
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-decoration: none;
}

.report-link i{
    margin-right: 8px;
    font-size: 12px;
    display: inline;
}

.mini-logo{
    width: 56px;
}

.menu-icon{
    display: none;
}

.fa-bars, .fa-times{
    cursor: pointer;
    font-size: 36px;
    transition: 250ms ease-out;
}

.nav-menu{
    list-style: none;
    display: flex;
    flex-direction: row;
    margin: 0;
}

.nav-item{
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
}

.nav-item:hover{
    border-bottom: 5px solid #eee;
    transition: 250ms ease-in;
}

.nav-links{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 20px 30px;
    text-decoration: none;
    color: white;
}

.nav-links-mobile{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 30px;
    color: white;
    background-color: #515a5a;
    font-family: "Raleway";
    font-size: 16px;
}

.nav-links-sub{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 10px 20px;
    text-decoration: none;
    color: black;
    background-color: #eee;
}

.nav-links-sub:hover{
    background-color: #7f7f7f;
    color: var(--primary);
}

.subnav-container{
    z-index: 1;
    position: absolute;
    top: 108px;
    list-style: none;
}

.subnav-container-mini{
    z-index: 1;
    position: absolute;
    top: 58px;
    list-style: none;
}

.nowcasting-menu{
    min-width: 173px;
}

.scrolled{
    position: fixed;
    top: 0;
    left: 0;
}

.join{
    color: var(--primary);
    border-color: var(--primary);
}

.join:hover{
    border-color: var(--primary);
}

.this{
    color: var(--primary);
    font-size: 18px;
    font-weight: bold;
}
.aderisci{
    background-color: var(--primary);
    color: #fff;
}

@media screen and (max-width: 960px) {
    .navbar-container{
        border-bottom: none;
        padding: 0;
    }

    .navbar-logo img{
        max-width: 80%;
    }

    .report-link{
        margin-right: 12px;
    }

    .nav-menu {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 100%;
        height: 90vh;
        position: absolute;
        left: -100%;
        top: 111px;
        opacity: 1;
        transition: all 750ms ease;
        z-index: 2;
    }

    .active{
        opacity: 1;
        left: 0px;
    }

    
    .mobile-scrolled{
        top: 65px;
        -webkit-transition: all 0.2s ease-in;
        -moz-transition: all 0.2s ease-in;
        -o-transition: all 0.2s ease-in;
        transition: all 0.2s ease-in;
    }

    .menu-icon{
        display: flex;
        flex-direction: column;
        justify-content: center;
        color: #fff;
        padding-right: 20px;
    }

    .nowcasting-menu{top: 0; width: 100%;}
    .nav-item{
        background-color:  #515a5a;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .subnav-container{
        position: relative;
    }

    .subnav-container-mini{
        position: relative;
    }

    .nav-item:hover{
        border-bottom: none;
        border-left: 5px solid #eee;
    }

    .join:hover{
        border-color: var(--primary);
    }
}