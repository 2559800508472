.item-container{
    display: flex;
    flex-direction: column;
}

.item-bar{
    background-color: #eeeeee;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    padding: 0 20px;
}

.item-data{
    height: auto;
    background-color: #fafafa;
    position: relative;
    top: 0px;
    transition: ease-in 2s;
    opacity: 1;
    z-index: -5;
}

.item-data-update{
    background-color: rgba(247, 148, 30, 0.1);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px 30px;
}

.item-data-update-title{
    flex-direction: row;
    display: flex;
}
.item-data-update-title > span{
    margin-right: 10px;
}

.item-data-update-info{
    display: "flex"; 
    flex-direction: column;
    align-items: center;
    text-align: right;
}

.item-data-item{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 30px;
}

.item-name{
    margin-left: 20px;
}

.item-name a{
    text-decoration: none;
    color: black;
}

.item-status{
    font-size: 8px;
    width: 8px;
    height: 8px;
}

.progress-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 200px;
}

.error-wrapper{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    min-height: 200px;
}

.red{
    color: tomato;
}

.standard{
    color: var(--primary);
}

.green{
    color: lime;
}

.hide{
    position: absolute;
    top: -340px;
    opacity: 0;
    transition: none;
}

.pointer{
    cursor: pointer;
}

@media screen and (max-width: 960px){
    .item-data-update-title{
        font-size: 14px;
    }

    .item-data-update-title > span{
        margin-right: 0;
    }
}