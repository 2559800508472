.nivometria-wrapper{
    min-height: 100vh;
    padding-top: 120px;
}

.nivometria-title{
    text-align: center;
    margin: 20px auto;
}

.nivometria-element{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 20px 50px;
}

.nivometria-element-data{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 80%;
    padding: 20px 0;
}

.nivometria-element-desc{
    margin: 10px 50px 40px 50px;
    text-align: justify;
}

.nivometria-element-values{
    list-style: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.nivometria-element-values:first-child{
    border: 20px 0 0 20px;
}

.nivometria-element-values li{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 1px solid gray;
}

.nivometria-element-values li p{
   text-align: center;
}


.nivometria-element-values li span:first-child{
    padding: 10px 20px;
    text-align: center;
    color: #fff;
}

.nivometria-element-values li p:nth-child(2){
    padding: 10px;
}

#nivometria-date span:first-child{
    background-color: rgb(177, 175, 175);
}

#nivometria-temp-min span:first-child{
    background-color: blue;
}

#nivometria-temp-max span:first-child{
    background-color: red;
}

#nivometria-last-hours span:first-child{
    background-color: var(--primary);
}

#nivometria-snow-height span:first-child{
    background-color: var(--primary);
}

@media screen and (max-width:960px){
    .nivometria-element{
        flex-direction: column;
        align-items: center;
        margin: 0;
    }

    .nivometria-element-values{
        flex-direction: column;
    }

    .nivometria-element-desc{
        margin: 20px 0;
    }
}