.radar-wrapper{
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 120px;
}

.radar-header{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.radar-protezione-civile{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.radar-protezione-civile h5{
    padding: 10px 0;
}

.radar-protezione-civile h5 a{
    color: var(--primary);
}

.radar-box{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.radar-box h5{
    padding: 0 0 20px 0;
}

.radar-box h5 a{
    color: var(--primary);
}