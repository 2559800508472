.archive-container{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 80%;
  }
  
.archive-title{
    padding: 24px 48px;
}

.no-data{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 24px 48px;
    border: 1px solid #efefef;
}

.month-stats{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-family: 'Fira Sans';
}

.month-stats-title{
    margin-top: 24px;
    padding: 12px 0;
}

.month-stats ul{
    list-style-type: none;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.month-stats ul li{
    margin: 0 12px;
}

.table-cell-head{
    font-family: "FuturaND" !important;
    font-size: 16px !important;
}

.table-cell{
    font-family: "FuturaND" !important;
    font-size: 14px !important;
}

#temp_avg{
    color: #aa0000;
}

#temp_min{
    color: #aa0000;
}

#temp_max{
    color: #aa0000;
}

#humidity_min{
    color: #1414aa;
}

#humidity_max{
    color: #1414aa;
}

#dewpoint_min{
    color: #a6006f;
}


#dewpoint_max{
    color: #a6006f;
}

#pressure_max{
    color: #83cb17;
}

#pressure_min{
    color: #83cb17;
}

#windspeed_avg{
    color: #ff8400;
}

#windgust_max{
    color: #ff8400;
}

#daily_rain{
    color: #00bbff;
}

#temp_min_month{
    color: #1414aa;
}

#temp_avg_min_month{
    color: #00bbff;
}

#temp_max_month{
    color: red;
}

#temp_avg_max_month{
    color: #ff8400;
}

#temp_avg_month{
    color: #83cb17;
}

#monthly_rain_month{
    color: #5e0061;
}

#windspeed_avg_month{
    color: rgb(124, 85, 0);
}

#windgust_max_month{
    color: #a6006f;
}

@media screen and (max-width: 960px){
    .month-stats{
        flex-direction: column;
    }

    .month-stats h3{
        text-align: center;
        margin-bottom: 12px;
    }

    .month-stats ul{
        display: flex;
        flex-direction: column;
        align-self: center;
        justify-content: space-around;
    }

    .month-stats ul li{
       text-align: center;
    }
}