
.mapboxgl-popup-content{
    padding:0;
    width: 240px;
    display: flex;
    justify-content: center;
}
.mapboxgl-popup-close-button{
    color: red;
    width: 20px;
    height: auto;
    margin: 5px;
    font-size: 24px;
}
.popup-marker{cursor: pointer;}
.popup-container{
    width: 240px;
    height: 150px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.popup-container p{
    margin:0;
}

.popup-head{
    display:flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 0;
}
.popup-title{
    font-weight: bold;
    font-size: 12px;
}
.popup-subtitle{
    font-size: 12px;
}

.popup-update{
    font-size: 12px;
    font-family: 'Times New Roman', Times, serif;
}

.popup-info{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 0 10px;
}
.popup-info-item{
    display:flex;
    flex-direction: column;
    align-items: center;
}
.popup-info-item i{
    width: auto;
}

.popup-info-item p{
    font-size: 14px;
}

.popup-link{
    text-align:center;
}

.popup-link-text{
    color: blue;
    text-decoration: none;
}

.popup-link-text:hover{
    opacity: 0.7;
}

.progress-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: auto;
}

.error-wrapper{
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: auto;
}