.chart-container{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 80%;
  padding-bottom: 48px;
}

#chart{
    width: 960px;
    height: 400px;
}

@media screen and (max-width: 960px){
  .chart-container{
    flex-direction: column;
    width: 100%;
    padding: 0 0 20px 0;
  }

  #chart{
    width: max-content;
    height: auto;
    margin-bottom: 20px;
 }
}