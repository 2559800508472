.rss-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.rss-feed {
    width: 500px;
    list-style: none;
}

.rss-feed-mobile {
    width: 100%;
    list-style: none;
}

.rss-feed>li:first-child a {
    border-radius: 0 0 0 0;
}

.rss-feed>li:last-child a {
    border-radius: 0 0 0 20px;
}

.rss-title {
    color: #fff;
    background-color: var(--primary);
    padding: 10px 20px;
    border-radius: 10px 0 0 0;
}

.rss-article {
    text-decoration: none;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid black;
    padding: 10px 20px;
    background-color: rgba(255, 255, 255, 0.8);
}

.rss-article:hover {
    background-color: rgba(221, 221, 221, 0.8);
}

.rss-article-title {
    font-size: 18px;
}

.rss-info h1 {
    color: #000;
}

.rss-info p {
    color: #000;
}

.rss-arrow {
    opacity: 1;
}

.dissolve-arrow {
    opacity: 0;
    transition: all 1s;
}

.rss-social-icons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 300px;
    list-style-type: none;
    margin-left: 20px;
}

.rss-social-icons li {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 50%;

}

.rss-social-facebook {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    text-decoration: none;
    background-color: #fff;
    padding: 5px 10px;
    color: #000;
    opacity: 0.7;
}

.rss-social-facebook:hover {
    opacity: 1;
}

.rss-social-instagram {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    text-decoration: none;
    background-color: #fff;
    padding: 5px 10px;
    color: #000;
    opacity: 0.7;
}

.rss-social-instagram:hover {
    opacity: 1;
}

.rss-social-icons :first-child a {
    border-radius: 10px 0 0 10px;
}

.rss-social-icons :last-child a {
    border-radius: 0 10px 10px 0;
}

@media screen and (max-width: 960px){

    .rss-title{
        padding: 10px 20px;
    }

    .rss-info{
        max-width: 90%;
    }

    .rss-article-title{
        font-size: 14px;
    }

    .rss-article-desc{
        font-size: 12px;
    }

    .rss-social-icons{
        margin-top: 20px;
    }
}