.statusOn{
    width: 12px;
    height: 12px;
    background-color: lime;
    border-radius: 50%;
    
}

.statusOff{
    width: 12px;
    height: 12px;
    background-color: red;
    border-radius: 50%;
}

.localityLink{
    text-decoration: none;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    color: #000;
}

.localityLink:hover{
    font-weight: bold;
}

.search-box-desktop{
    margin: 10px 5px;
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}