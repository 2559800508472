#nivometria-chart{
    max-width: 800px;
    max-height: 400px;
}

.nivometria-chart-container div{
    max-width: 600px;
}

.nivometria-chart-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 50px 50px;
}

@media screen and (max-width: 960px) {
    .nivometria-chart-container{
        flex-direction: column;
        padding: 0;
        margin: 20px 0;
    }

    .nivometria-chart-container div{
        max-width: 300px;
    }
}