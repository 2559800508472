.satellite-wrapper{
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 120px 0 50px 0;
}

.satellite-header{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
}

.satellite-body{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.satellite-image-grid{
    width: 100%;
    height: 100%;
}