.error-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 0;
}

.error-container img{
    max-width: 512px;
}

.error-container p{
    font-size: 24px;
    color: var(--primary);
    margin-top: 20px;
    text-align: center;
}

@media screen and (max-width: 960px){
    .error-container img{
        max-width: 90%;
    }
}