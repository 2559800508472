.footer{
    display: flex;
    position: static;
    bottom: 0;
    left: 0;
    flex-direction: column;
    height: auto;
    background-color: gray;
    padding: 0 50px;
    justify-content: space-between;
    align-items: center;
}

.footer-row{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding: 10px 0;
}

.footer-content{
    width: 100%;
}

#collaborazioni-title{
    color: #fff;
    padding: 10px 20px;
}

.info{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.copyright{
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.spacing{margin: 0 2px; color: #fff;}
.author{
    font-style: italic;
    color: white;
}

.social-icons{
    width: 70px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.policy a{
    text-decoration: none;
    color: white;
    font-size: 16px;
    font-family: "Raleway";
    padding: 0 8px;
}

@media screen and (max-width: 960px){
    .author{
        display: none;
    }
    .spacing{
        display: none;
    }

    .policy{
        display: flex;
        flex-direction: row;
        align-items: center;
    }
}

@media screen and (max-width: 480px){
    .copyright{
        font-size: 12px;
    }
    .policy{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .policy a{
        font-size: 12px;
        padding: 4px 0;
    }
    .social-icons{
        flex-direction: column;
    }
}