.chisiamo-container{
    min-height: 100vh;
    max-width: 100vw;
    display: flex;
    flex-direction: column;
}

.chisiamo-row{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 50px;
    margin: 20px auto;
    max-width: 50%;
}

.content p{
    text-align: justify;
    margin: 10px 0;
}



@media screen and (max-width: 960px){
    #chisiamo-cover{
        margin-top: 76px;
    }

    .chisiamo-row{
        flex-direction: column;
    }

    .content{
        margin: 20px auto;
        max-width: 80%;
        padding: 0;
    }
    
    .column-reversed-mobile{
        flex-direction: column-reverse;
    }

    .content img{
        width: 256px;
        margin: 20px 0;
    }
}