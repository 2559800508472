.contatti-container{
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 0 0 50px 0;
}

.contatti-wrapper{
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center ;
    margin: 0 0 50px 0;
}

.contatti-title{
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 30px;
}

.contatti-button{
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.contatti-label{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    padding: 0 30%;
    margin: 20px 0;
}

.contatti-input{
    width: 40%;
    height: 30px;
    border-radius: 20px;
    padding-left: 10px;
    border: 1px solid black;
}

.contatti-input:focus{
    width: 80%;
    transition: 1s all;
}
.contatti-textarea-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-bottom: 5vw;
}
.contatti-textarea{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 0 20px 0;
    padding: 0 30%;
}

.centered{
    justify-content: center;
}

.recaptcha-contatti{
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 20px;
}

@media screen and (max-width: 960px) {
    .contatti-label{
        padding: 0 10%;
    }

    .contatti-input{
        width: 70%;
    }

    .contatti-input:focus{
        width: 70%;
    }

    .contatti-textarea-container{
        justify-content: center;
        width: 100vw;
        padding: 0 5vw 10vw 5vw;
    }

    .contatti-textarea{
        padding: 0 10%;
    }

    .contatti-textarea:focus{
        height: 150px;
    }

}
