.report-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 128px 0px;
    min-height: 100vh;

    .report-title {
        padding: 24px 0;

        h1 {
            text-align: center;
        }

        h3{
            text-align: center;
        }
    }

    .image-picker {
        padding: 0 48px;
    }

    .image-form {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        width: 100%;
    }

    .image-form>h3 {
        text-align: center;
    }

    .image-form>.checked-image>img {
        max-width: 480px;
        border-radius: 24px 24px 0 0;
    }

    .image-form>.checked-image>.checked-image-buttons {
        font-size: 24px;
        width: 100%;
        padding: 12px 24px;
        box-sizing: border-box;
        display: flex;
        justify-content: space-around;
    }

    .image-form>.checked-image>.checked-image-buttons>i {
        margin: 0 8px;
    }

    .image-form>.checked-image>.checked-image-buttons>a>.fa-trash-can {
        color: gray;
    }

    .image-form>.checked-image>.checked-image-buttons>a>.fa-circle-down {
        color: darkcyan;
    }

    .image-form>.checked-image {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        border: 1px solid black;
        border-radius: 24px;
        margin-top: 12px;
    }

    .report-picture-btn {
        margin-top: 24px;
        width: 100%;
        display: block;
    }

    .report-send-complete {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        h3 {
            color: green;
        }
    }
}

.cwd-camera {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;

    .image-preview>span {
        position: absolute;
        bottom: 0;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        width: 100%;
        font-size: 48px;
        padding: 24px 0;
    }

    .image-preview>span>a>.fa-check {
        color: lime;
    }

    .image-preview>span>a>.fa-close {
        color: red;
    }
}

.camera-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    position: absolute;
    bottom: 0;
    align-items: center;

    .btn-take-picture {
        background-color: red;
        border-radius: 50%;
        width: 72px;
        height: 72px;
        align-self: center;
        border: 1px solid white;
        margin: 12px;
    }

    .btn-rotate-camera {
        color: #fff;
        font-size: 24px;
    }
}


@media screen and (max-width: 960px) {
    .report-container {
        justify-content: center;
        padding: 24px;

        .image-form {
            flex-direction: column;
            align-items: center;
            width: auto;

            .checked-image{
                margin-bottom: 24px;
            }
        }

        .image-form>.checked-image>img {
            max-width: 100%;
        }

        .report-send-complete {
            h3 {
                margin: 48px 0;
                color: green;
            }
        }
    }


}