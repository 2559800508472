.aderisci-wrapper{
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 0 0 20px 0;
}

.aderisci-title{
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
}

.aderisci-input{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    margin: 10px 0;
}

.aderisci-datipersonali{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 30%;
    padding: 20px 0;
}

.aderisci-strumentazione{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
    padding: 20px 0;
}

.aderisci-localita{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 30%;
    padding: 20px 0;
}

.aderisci-send{
    margin: 20px 0;
}

.check-privacy{
    margin: 20px auto;
    text-align: center;
    width: 50%;
}

.separator{
    width: 90%;
    height: 3px;
    background-color: var(--primary);
    border: 0;
}

@media screen and (max-width: 960px){
    .aderisci-datipersonali{
        width: 90%;
    }
    .aderisci-strumentazione{
        width: 90%;
    }
    .aderisci-localita{
        width: 90%;
    }
    .aderisci-input{
        width: 100%;
    }

    .check-privacy{
        width: 90%;
    }
}