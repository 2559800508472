
@import url('https://fonts.googleapis.com/css2?family=Raleway&display=swap');
@import "~react-image-gallery/styles/css/image-gallery.css";

*{
 padding: 0;
 margin: 0; 
}

:root {
  --primary: #f7941e;
  --secondary: #000;
  --cyan: #2e86c1;
  --search: #3f51b5;
}

body{
  font-family: 'Raleway';
}

.maintenence-image{
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.maintenence-title{
  color: var(--primary);
  text-transform: uppercase;
}