
.btn {
    padding: 8px 20px;
    border-radius: 2px;
    outline: none;
    border: none;
    cursor: pointer;
}

.btn--primary {
    background-color: var(--primary);
    color: #fff;
    border: 1px solid var(--primary);
}


.btn--primary-hover {
    transition: all 0.3s ease-out;
    background: #fff;
    color: var(--primary);
    transition: 250ms;
}


.btn--black {
    background-color: var(--secondary);
    color: #fff;
    border: 1px solid var(--secondary);
}

.btn--black:hover {
    transition: all 0.3s ease-out;
    background: #fff;
    color: #000;
    transition: 250ms;
}


.btn--cyan {
    background-color: var(--cyan);
    color: #fff;
    border: 1px solid var(--cyan);
}

.btn--cyan-checked {
    background: #fff;
    color: var(--cyan);
}

.btn--cyan:hover {
    transition: all 0.3s ease-out;
    background: #fff;
    color: var(--cyan);
    transition: 250ms;
}

.btn--outline {
    background-color: transparent;
    color: var(--primary);
    padding: 8px 20px;
    border: 1px solid var(--primary);
    transition: all 0.3s ease-out;
}


.btn--outline-hover {
    transition: all 0.3s ease-out;
    background: var(--primary);
    color: #fff;
    transition: 250ms;
}


.btn--outline-cyan {
    background-color: transparent;
    color: var(--cyan);
    padding: 8px 20px;
    border: 1px solid var(--cyan);
    transition: all 0.3s ease-out;
}

.btn--outline-cyan:hover {
    transition: all 0.3s ease-out;
    background: var(--cyan);
    color: #fff;
    transition: 250ms;
}

.btn--small{
    padding: 4px 10px;
    font-size: 14px;
    border-radius: 5px;
}

.btn--medium {
    padding: 8px 20px;
    font-size: 18px;
    border-radius: 10px;
}

.btn--large {
    padding: 12px 26px;
    font-size: 20px;
    border-radius: 10px;
}

.no--style{
    background: transparent;
}

.no--style:hover{
    background-color: transparent;
}

.full-width{
    width: 100%;
}

.full-height{
    height: 100%;
}

.no-rounded{
    border-radius: 0;
}

.white{
    color: #fff;
}