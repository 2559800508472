.stations-container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    margin: 50px 0;
}

/* SCROLL EFFECT*/


.province-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.province-title{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
    padding: 0 20%;
}

.province-name{
    text-transform: uppercase;
}

.province-list{
    list-style: none;
    padding: 0 20%;
}

/* FILTERS */
.filters{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.search{display: flex; flex-direction: column;}
.search-title{
    padding: 10px 20%;
    display: flex;
    align-self: center;
}

.search-box{
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 10px 20%;
    align-items: center;
}

.search-button{
    margin-left: 10px;
}

.buttons-sort{
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.buttons-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
}

/*MOBILE WRAPPER*/
.mobile-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.mobile-titlebar{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-self: center;
    align-items: center;
    width: 90%;
    padding: 10px 0 20px 0;
}
.mobile-titlebar p{
    font-size: 32px;
    margin: 0;
    text-transform: uppercase;
    text-align: center;
}
.mobile-titlebar i{
    font-size: 48px;
    cursor: pointer;
}

.mobile-titlebar i:active{
    color: #000;
}

.mobile-notfound{
    display: flex;
    flex-direction: row;
    justify-content: center;
    min-height: 100px;
    align-items: center;
}

.progress-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 400px;
}

.hide{
    display: none;
}

@media screen and (max-width: 960px){
    .stations-container{
        width: 100vw;
    }
    .province-list{
        list-style: none;
        width: 100%;
        padding: 0;
    }

    .buttons-container{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .search-box{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin: 0 5%;
    }

    .filters{
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }

    .province-title{
        align-self: center;
        padding:0;
        width: 100%;
    }
}

