.page-container {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-top: 120px;
}

.page-commands {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px 10px;
    width: 90%;
    align-items: center;
}

.page-title {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.page-title>p {
    font-size: 18px;
    font-weight: 500;
}

.page-title>h2 {
    font-weight: bold;
    margin-left: 5px;
    text-transform: uppercase;
}

.page-data {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 90%;
    padding: 20px 0;
}

.station-image {
    width: 300px;
    height: 300px;
    /*border: 1px solid black;*/
    background-position: center;
    background-repeat: no-repeat;
}

.station-data {
    width: 90%;
    display: flex;
    min-height: 100%;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.station-data-update {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.station-data-title {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    padding: 5px 0;
}

.station-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
}

.station-data-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 80%;
}

.station-data-item {
    font-family: "FuturaND";
    padding: 10px 30px;
    margin: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    border: 1px solid black;
    width: 300px;
}

.station-data-current {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.station-data-icon {
    background-size: contain;
    background-repeat: no-repeat;
    width: 32px;
    height: 32px;
    margin-right: 12px;
}

.station-data-large {
    width: 600px;
}

.station-data-item-title {
    font-size: 18px;
    margin: 8px 0;
    text-align: center;
}

.station-data-item>span>.min {
    color: #2e86c1;
    font-size: 12px;
    padding: 0 5px;
}

.station-data-item>span>.max {
    color: red;
    font-size: 12px;
    padding: 0 5px;
}

.station-data-item>span>.excursion {
    color: rgb(0, 255, 161);
    font-size: 12px;
    padding: 0 5px;
}

.station-data-current>.now {
    color: black;
    font-size: 28px;
    text-align: center;
}

.station-data-other {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.station-data-block {
    width: 100%;
    margin: 12px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.station-data-row i {
    width: 18px;
    text-align: center;
    margin-right: 4px;
}

.station-data-row>span {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    font-weight: bold;
    font-size: 12px;
}

.station-data-max {
    color: var(--primary);
}

.station-data-min {
    color: #2e86c1;
}

.station-data-media {
    color: darkgreen;
}

.station-data-excursion {
    color: goldenrod;
}

.data-buttons {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    min-width: 250px;
    height: 98px;
    align-self: center;
}

.page-ubicazione {
    padding: 20px 0;
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.ubicazione-title {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 15px 0 0 0;
}

.ubicazione-description {
    font-family: 'Raleway';
}

.ubicazione-list {
    list-style: none;
    display: flex;
    flex-direction: column;
    align-self: center;
    width: 100%;
    padding: 10px 0;
}

.ubicazione-station {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.ubicazione-list li {
    padding: 5px 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.ubicazione-url {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.ubicazione-url a {
    color: #2e86c1;

}

.page-microclima {
    width: 90%;
    padding: 20px 0;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-around;
}

.microclima-block {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.microclima-title {
    padding: 10px 0;
    text-align: center;
}

.microclima-desc {
    text-align: justify;
    font-family: "Raleway";
}

.microclima-image {
    width: 300px;
    height: 300px;
    /*border: 1px solid black;*/
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.chart-title {
    margin: 40px 0;
    text-align: center;
}

.page-widget {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin: 20px 0;
    width: 90%;
}

.widget-title {
    width: 60%;
}

.widget-banner {
    width: 40%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.separator {
    width: 90%;
    height: 3px;
    background-color: var(--primary);
}

@media screen and (max-width: 960px) {
    .page-title {
        flex-direction: column;
        margin: 10px 0;
    }

    .page-title>p {
        text-align: center;
        font-size: 12px;
    }

    .page-title>h2 {
        text-align: center;
        font-size: 14px;
    }

    .page-commands {
        flex-direction: column;
        align-items: center;
    }

    .page-data {
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    .station-data-update {
        flex-direction: column;
        margin-top: 24px;
    }

    .station-data-large {
        width: 100%;
    }

    .station-data {
        width: 100%;
    }

    .station-data-item {
        width: 100%;
        margin: 12px 0;
        padding: 24px 0;
        border-bottom: 1px solid black;
        border-top: 1px solid black;
        border-right: none;
        border-left: none;
    }

    .station-data-title {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .station-row {
        flex-direction: column;
        width: 100%;
    }

    .station-data-other {
        flex-direction: column;
        width: 100%;
    }

    .station-data-block {
        flex-direction: column;
        width: 100%;
        margin: 0;
    }

    .page-ubicazione {
        flex-direction: column-reverse;
    }

    .page-microclima {
        flex-direction: column-reverse;
        align-items: center;
    }

    .microclima-block {
        width: 100%;
    }

    .microclima-desc {
        margin-bottom: 10px;
    }

    .page-widget {
        flex-direction: column;
    }

    .widget-banner img {
        width: 350px;
    }

    .widget-title {
        width: 100%;
        margin: 20px 0;
    }

    .ubicazione-station {
        width: 100%;
    }

    .ubicazione-description {
        padding: 0 0 20px 0;
    }

    .ubicazione-list {
        width: 90%;
    }

    .ubicazione-list li{
        flex-direction: column;
        align-items: center;
    }

    .ubicazione-url {
        padding: 0 0 50px 0;
    }

    .station-image {
        margin-bottom: 20px;
        width: 100%;
    }
}