.slider-container{
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    z-index: 0;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.slider-content-row{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}


.overlay-data{
    display: flex;
    flex-direction: column;
    z-index: 100;
    position: relative;
    /*margin-left: 50px;*/
}


/* --------------------------------- */
.overlay{
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    width: 100%;
    height: 100%;
    /*padding-top: 250px;*/
}

.overlay-mobile{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
    width: 100%;
    height: 100%;
    padding-top: 150px;
    font-size: 12px;
}

.overlay-row{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.overlay-data{
    display: flex;
    flex-direction: column;
    z-index: 100;
    position: relative;
    /*margin-left: 50px;*/
}

.slider{
    height: 100vh;
    z-index: 0;
    background-color: #fff;
}

.slider-nodata{
    z-index: 0;
    background-color: #fff;
}

.awssld__content{
    background-color: #fff;
}

.slider img{
    opacity: 0.7;
}

.data-container{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background-color: rgba(238,238,238,0.9);
    border-radius: 0 10px 10px 0;
    margin: 10px 0;
    width: max-content;
    max-width: 600px;
}

.data-title{
    background-color: var(--primary);
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    border-radius: 0 10px 0 0;
    padding: 20px;
}

.data-table{
    border-collapse: collapse;
}

.data-table tbody{
    padding: 20px;
    display: table;
}

.data-table tbody td{
    padding: 10px 20px;
}

.data-table .param{
    text-transform: uppercase;
    border-top: 1px solid gray;
    min-width: 120px;
}

.data-table .value{
    border-top: 1px solid gray;
}

.data-table .value a{
    color: #995252;
    font-weight: 300;
    text-decoration: none;
}

.card-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
    margin: 0 20%;
    border-radius: 10px;
    border: 3px solid rgba(127, 127, 127, 0.9);
    background-color: rgba(238,238,238,0.9);
}

.card-title{
    font-weight: bold;
    color: black;
    font-size: 24px;
}

.card-description{
    font-weight: normal;
    color: #000;
    font-size: 18px;
    margin-bottom: 10px;
}

.card-button{
    display: flex;
    flex-direction: row;
    align-self: flex-start;
}

@media screen and (max-width:960px) {

    .card-container{
        margin: 0 10px;
    }

    .card-description{
        font-size: 16px;
    }

    .overlay-data{
        margin: 0;
        top: 50px;
        bottom: 0px;
    }
    .data-container{
        width: min-content;
    }

    .slider-container{
        font-size: 12px;
    }

    .data-title{
        border-radius: 10px 10px 0 0 ;
    }

    .slider-content-row{
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    .data-container{
        border-radius: 10px;
    }

    
}

@media screen and (max-height: 700px) {

    .slider-container{
        font-size: 10px;
    }
}
