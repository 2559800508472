.home-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.home-section {
    height: 360px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    max-width: 50%;
}

.home-section-banner {
    height: 360px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.section-img {
    width: 300px;
    height: 300px;
    background-size: cover;
}

.section-img:hover {
    animation: shake 1s;
    animation-iteration-count: infinite;
}

.section-title {
    width: 100%;
    text-align: left;
}

.section-link {
    color: cadetblue;
    text-decoration: none;
    margin-top: 40px;
    font-size: 24px;
}

.section-link:hover {
    color: rgb(70, 135, 138);
}

.section-info {
    display: flex;
    flex-direction: column;
    max-width: 50%;
    justify-content: center;
    align-items: center;
}

.blog-banner {
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: 40%;
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.blog-banner-background {
    background-image: url('../img/FotoChry.jpg');
    background-size: cover;
    background-position: 0 50%;
    position: absolute;
    opacity: .5;
    width: 100%;
    height: 360px;
}

.blog-arrow-icon {
    position: relative;
    right: 50px;
    bottom: 0px;
    transition: all 0.6s;
}

.pull-up-arrow {
    bottom: -100px;
    opacity: 0;
}

.arrow-row{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
}

.slide-bottom {
    position: absolute;
	-webkit-animation: slide-bottom 5s linear infinite forwards;
	        animation: slide-bottom 5s linear infinite forwards;
}

@media screen and (max-width:960px) {

    .blog-banner {
        background-size: 80%;
    }

    .blog-arrow-icon {
        display: none;
    }

    .home-section {
        flex-direction: column;
        height: auto;
        padding: 40px 20px;
        max-width: 100%;
        align-items: center;
    }

    .reverse-col {
        flex-direction: column-reverse;
    }

    .section-info {
        max-width: 100%;
        margin-top: 25px;
    }

    .section-info p {
        text-align: justify;
    }

    .section-link {
        width: 100%;
        text-align: center;
    }

    /*
    .awssld__wrapper{
        height: auto;
    }

    .slider{
        height: auto;
    }
    */
}

@keyframes shake {
    0% {
        transform: translate(1px, 1px) rotate(0deg);
    }

    10% {
        transform: translate(-1px, -2px) rotate(-1deg);
    }

    20% {
        transform: translate(-3px, 0px) rotate(1deg);
    }

    30% {
        transform: translate(3px, 2px) rotate(0deg);
    }

    40% {
        transform: translate(1px, -1px) rotate(1deg);
    }

    50% {
        transform: translate(-1px, 2px) rotate(-1deg);
    }

    60% {
        transform: translate(-3px, 1px) rotate(0deg);
    }

    70% {
        transform: translate(3px, 1px) rotate(-1deg);
    }

    80% {
        transform: translate(-1px, -1px) rotate(1deg);
    }

    90% {
        transform: translate(1px, 2px) rotate(0deg);
    }

    100% {
        transform: translate(1px, -2px) rotate(-1deg);
    }
}

@-webkit-keyframes slide-bottom {
    0% {
      -webkit-transform: translateY(0) ;
              transform: translateY(0) ;
    }
    100% {
      -webkit-transform: translateY(25px) ;
              transform: translateY(25px) ;
    }
  }
  @keyframes slide-bottom {
    0% {
      -webkit-transform: translateY(0) ;
              transform: translateY(0) ;
    }
    100% {
      -webkit-transform: translateY(25px);
              transform: translateY(25px) ;
    }
  }
  