body,
html,
#root {
    height: 100%;
}

#map {
    height: 100%;
    z-index: 0;
}

.circle-marker {
    border-radius: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-width: 2px;
    border-style: solid;
    font-size: 9px;
}

.report-marker-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    background-color: #fff;
    border-radius: 100%;
    width: 24px;
    height: 24px;
}

.report-marker-container .fa-bell {
    margin-top: 2px;
    color: goldenrod;
    font-size: 18px;
}

.snow-marker {
    border-radius: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-width: 2px;
    border-style: solid;
    font-size: 10px;
}

.snow-marker-icon {
    font-size: 24px;
    color: #eee;
}

.new-report-marker{
    font-size: 8px;
    color: goldenrod;
    position: absolute;
    right: -10px;
    bottom: 2px;
}

.multiple-reports{
    font-size: 12px;
    color: #fff;
    position: absolute;
    bottom: 8px;
    right: -8px;
}

.report-popup-container .leaflet-popup-content {
    margin: 0;
    width: auto !important;
}

.report-popup-container .leaflet-popup-content-wrapper {
    padding: 0;
}

.report-popup {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 256px;
}

.report-popup>img {
    max-width: 256px;
}

.report-prev-btn {
    cursor: pointer;
    position: absolute;
    left: 12px;
    top: 50%;
}

.report-prev-btn i {
    font-size: 24px;
    color: var(--primary);
}

.report-next-btn {
    cursor: pointer;
    position: absolute;
    right: 12px;
    top: 50%;
}

.report-next-btn i {
    font-size: 24px;
    color: var(--primary);
}

.report-dots{
    text-align: center;
}

.report-dots a{
    color: var(--primary);
    margin: 0;
    display: inline;
}

.report-dots a:hover{
    cursor: pointer;
}

.report-popup-date {
    position: absolute;
    top: 19px;
    left: 0px;
    color: #eee;
    font-weight: bold;
    background-color: rgba(0, 0, 0, 0.3);
    padding: 4px 8px;
}

.report-popup-city {
    padding: 12px 0 0 0;
}

.report-popup-locality {
    padding: 0 0 12px 0;
}

.report-popup-metadata {
    padding: 12px 0;
}

.report-popup .report-popup-metadata>p {
    margin: 0;
}

.report-popup>.expiration {
    color: red;
    font-weight: bold;
}

.report-popup-image-loading{
    display: flex;
    height: 128px;
    width: 128px;
    justify-content: center;
    align-items: center;
}

.rectangle-marker {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-width: 2px;
    border-style: solid;
    font-size: 10px;
    width: 36px !important;
    height: 18px !important;
    border-radius: 10%;
}

.control-buttons-list-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
}


.leaflet-bar button {
    display: block;
    border: 0;
    border-bottom: 1px solid gray;
    overflow: hidden;
    background-color: white;
}

.leaflet-bar button:hover {
    background-color: gray;
}

.leaflet-bar button:first-of-type {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.leaflet-bar button:last-of-type {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

.control-button {
    font-size: 18px;
    width: 30px;
    height: 30px;
    line-height: 30px;
    display: block;
    background-color: transparent;
}

.active-filter {
    background-color: var(--primary);
}

.temp-min {
    color: blue;
}

.temp-max {
    color: red;
}

.humidity {
    color: cornflowerblue;
}

.excursion {
    color: aquamarine;
}

.snowflake-color {
    color: #5ea6ee;
}

.snowflake-button-checked {
    color: white;
    background-color: var(--primary);
}

.wind-dir-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 70px;
    height: 70px;
}

.wind-dir-container p {
    position: absolute;
    font-weight: bold;
    color: black;
    font-size: 10px;
    z-index: 201;
}

.wind-marker-wrapper {
    display: block;
    flex-direction: row;
    align-items: center;
}

.wind-marker-text {
    font-size: 450px;
    font-weight: bold;
    fill: white;
}

.wind-marker {
    fill: red;
}



#img-overlay {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 500;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

#img-overlay .close-button {
    font-size: 36px;
    color: red;
    position: relative;
    right: 0;
}

#img-overlay .close-button:hover {
    cursor: pointer;
}

#img-overlay .img-overlay-box {
    background-color: white;
    border-radius: 20px;
    width: 500px;
    display: flex;
    flex-direction: column;
}

#img-overlay .img-overlay-box span {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 5px 0;
}

#img-overlay .img-overlay-box span a {
    color: var(--primary);
    text-decoration: none;
}

#img-overlay .img-overlay-container {
    display: flex;
    flex-direction: row;
}

#img-overlay img {
    width: 100%;
    padding: 10px;
    border-radius: 20px;
    box-sizing: border-box;
}

.webcam-marker {
    color: black;
    font-size: 16px;
    background-color: white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
}

#map-logo img {
    width: 96px;
}

#map-logo {
    position: absolute;
    z-index: 1000;
    bottom: 16px;
    left: 0;
    background-color: rgba(255, 255, 255, 0.7);

}

@media screen and (max-width: 960px) {
    #map-logo img {
        width: 96px;
    }

    #map-logo {
        position: absolute;
        z-index: 1000;
        bottom: 32px;
        left: 0;
        background-color: rgba(255, 255, 255, 0.7);
    }

    #map-overlay div {
        display: block;
        width: 100%;
    }

    #img-overlay .close-button {
        position: absolute;
        right: 25px;
        margin-top: 10px;
    }

    #img-overlay .img-overlay-container {
        width: 90%;
    }
}