.informativa-wrapper{
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

#informativa-title{
    margin-top: 50px;
}

.disclaimer{
    width: 80%;
    margin: 20px 0;
}
.disclaimer h3{
    margin: 10px 0;
}

.disclaimer ul li{
    margin-bottom: 5px;
}

.disclaimer ul li p{
    text-align: justify;
}

.condizioni-di-utilizzo{
    width: 80%;
    margin: 20px 0;
}